<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item :to="{
              name: 'DashboardHome',
            }">
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>

            <b-breadcrumb-item :to="{
              name: 'Branches',
            }">分店列表</b-breadcrumb-item>
            <b-breadcrumb-item active>分店批次上傳</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <div class="row">
        <div class="col-12 col-xl-6 d-flex align-items-center">
          <h4 class="mb-2 mb-xl-0 mr-2 font-weight-bold">分店批次上傳</h4>
        </div>
      </div>      
      <div class="d-flex flex-column flex-xl-row mt-4 mb-3 justify-content-between">
        <b-breadcrumb class="mb-2 p-0" :items="breadcrumb"></b-breadcrumb>
        <div>
          <b-form-file id="fileInput" v-model="form.file" plain hidden @change="handleUploadFile" placeholder="尚未選擇檔案"
            browse-text="瀏覽"></b-form-file>
          <b-button class="flex-shrink-0 mb-2 mb-xl-0" variant="primary" tag="label" for="fileInput">
            請選擇檔案</b-button>

          <b-button class="ml-4" variant="primary" @click="handleSubmitFile">確定上傳</b-button>
        </div>


      </div>

      <div class="row my-3">
        <div class="col-12 col-xl-3">
          <span v-if="branch">
            上層分店： {{ branch.name }}
          </span>
          <span v-else>
            上層分店： 最上層
          </span>
        </div>
        <div class="col-12 col-xl-3 offset-1 d-flex align-items-center">

        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="accordion" role="tablist">
            <b-card no-body class="mb-1" v-for="(key, index) in Object.keys(results)" :key="index">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button v-if="key === 'failed'" block v-b-toggle.accordion-failed variant="info">失敗：{{ results.failed.length
                }}筆</b-button>
                <b-button v-if="key === 'created'" block v-b-toggle.accordion-created variant="info">新增：{{
                  results.created.length }}筆</b-button>
                <b-button v-if="key === 'existed'" block v-b-toggle.accordion-existed variant="info">更新：{{
                  results.existed.length }}筆</b-button>
              </b-card-header>
              <b-collapse :id="`accordion-${key}`" visible role="tabpanel">
                <b-card-body>
                  <b-table striped hover responsive :items="results[key]" :fields="fields" :busy="isLoading">
                    <template #table-busy>
                      <div class="text-center my-2">
                        <b-spinner class="align-middle"></b-spinner>
                      </div>
                    </template>
                  </b-table>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import branchApi from "../../../apis/branch";

import "bootstrap-vue/dist/bootstrap-vue.css";

export default {
  data() {
    return {
      branch: null,
      isLoading: false,
      form: {
        file: null,
      },
      results: {
        failed: [],
        created: [],
        existed: [],
      },
      fields: [
        {
          key: "row",
          label: "第幾列",
        },
        {
          key: "branch_code",
          label: "店代碼",
        },
        {
          key: "outer_code",
          label: "外部編號",
        },
        {
          key: "name",
          label: "店名",
        },
        {
          key: "brand",
          label: "品牌名稱",
        },
        {
          key: "biz_hours",
          label: "營業時間",
        },
        {
          key: "telephone",
          label: "電話",
        },
        {
          key: "location_zipcode",
          label: "郵遞區號",
        },
        {
          key: "location_address",
          label: "地址",
        },
        {
          key: "location_city",
          label: "縣市",
        },
        {
          key: "location_state",
          label: "區域",
        },
        {
          key: "fax",
          label: "傳真",
        },
        {
          key: "lat",
          label: "緯度",
        },
        {
          key: "long",
          label: "經度",
        },
        {
          key: "map_url",
          label: "GOOGLEMAP",
        },
        {
          key: "status",
          label: "狀態",
        },
      ],
    };
  },

  mounted() {
    this.fetchBranch();
  },

  methods: {
    async fetchBranch() {
      if (this.$route.params.branchID) {
        const { data } = await branchApi.getBranch(this.$route.params.branchID);
        this.branch = data.data;
      }
    },

    handleBack() {
      this.$router.push({ name: "Branches" });
    },

    handleUploadFile(e) {
      const data = new FormData();

      data.append("file", new Blob(["test payload"], { type: "text/csv" }));

      console.log("handleUploadFile", e, e.target.files);
    },

    async handleSubmitFile() {
      try {
        this.isLoading = true;
        const fd = new FormData();
        fd.append("file", this.form.file);
        if (this.branch) {
          fd.append("parent_branch_id", this.branch.id);
        }
        const { data } = await branchApi.import(
          fd
        );
        this.results = {
          ...data.data,
        };
        this.isLoading = false;

        console.log(data);
      } catch (e) {
        console.log(e);
      }
    },
  },
  computed: {
    breadcrumb() {
      const root = {
        text: "最上層",
        to: {
          name: "Branches",
        },
      };

      const parents =
        this.branch?.parents.map((parent) => {
          return {
            text: parent.parent_name,
            to: {
              name: "BranchesSubLayer",
              params: { branchID: parent.parent_id },
            },
          };
        }).concat({
        text: this.branch?.name,
        to: {
              name: "BranchesSubLayer",
              params: { branchID: this.branch?.id },
            },
        //active: true,
      }).concat({
        text: "分店批次上傳"
      }) ?? [];

      return this.branch ? [root, ...parents] : [root];
    },
  }
};
</script>

<style></style>
